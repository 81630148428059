import './App.css';
import React from "react";
import { Route, Routes} from "react-router-dom";
import VideoPageView from "./components/VideoPageView"
import LandingPage from "./components/LandingPage"

export const config = {
  endpoint:`https://64714415-e6d5-4f3f-9468-7a076d8361e3.mock.pstmn.io/v1`
};

function App() {
  return (
    <div className="App">
    <React.StrictMode>
       <Routes>
            <Route exact path ="/" element={<LandingPage/>} />
            <Route path="/video/:id" element={<VideoPageView/>} />
      </Routes>
    </React.StrictMode>
    </div>
  );
}

export default App;
